<template>
  <b-media
    class="notification-card "
    :class="(!notification.isRead && unReadNotifications!=0) && 'notification-card--active'"
  >
    <template #aside>
      <b-avatar
        size="42"
        :variant="displayNotification.type"
        :src="getAvatar(displayNotification.avatar)"
      />
    </template>
    <div class="media-heading d-flex justify-content-between mb-0 mb-md-1">
      <p class="font-weight-bolder text-capitalize mb-0">
        {{ displayNotification.title }}
      </p>
      <div v-if="notification.notification.walletShareInviteAction === 'REQUESTED' && notification.notification.invitation.processedAt">
        <b-badge
          v-if="notification.notification.invitation.accepted"
          variant="success"
        >
          Accepted
        </b-badge>
        <b-badge
          v-else
          variant="danger"
        >
          Declined
        </b-badge>
      </div>
      <div v-else>
        <b-badge
          v-if="isInviteRevoked(notification.notification.share.invites.data, notification.notification.invitation.checksum)"
          variant="danger"
        >
          Revoked
        </b-badge>
      </div>
    </div>
    <small class="notification-text">{{ displayNotification.subtitle }}</small>

    <!-- Review Button -->
    <div class="text-right">
      <b-button
        v-if="notification.notification.walletShareInviteAction === 'REQUESTED' && !notification.notification.invitation.processedAt && !isInviteRevoked(notification.notification.share.invites.data, notification.notification.invitation.checksum)"
        size="sm"
        variant="primary"
        @click="$bvModal.show(`wallet-share-invite-${notification.notification.invitation.checksum}-modal`)"
      >
        Review
      </b-button>
    </div>
    <div class="text-right">
      <small class="text-mute">
        {{ fromNow(UTCtoLocal(displayNotification.createdAt)) }}
      </small>
    </div>

    <b-modal
      :id="`wallet-share-invite-${notification.notification.invitation.checksum}-modal`"
      title="Review Account Share Invitation"
      ok-title="Accept"
      ok-variant="success"
      cancel-title="Decline"
      cancel-variant="danger"
      no-close-on-backdrop
      :busy="isProcessing"
      @ok="handleSubmit"
      @cancel="handleSubmit"
    >
      <b-overlay :show="isProcessing">
        {{ notification.notification.creator.name }} wants to share '{{ notification.notification.share.description }}' account with you.

        <div class="d-flex pt-2 pb-1 ml-1 mb-1">
          <b-avatar
            :size="62"
            class="mr-2"
          >
            <i
              :class="getWalletIcon(notification.notification.share.wallet.__typename)"
              class="fa-24"
            />
          </b-avatar>

          <div>
            <h4 class="mb-0">
              {{ notification.notification.share.description }}
            </h4>
            <p class="small mb-0">
              {{ notification.notification.share.wallet.__typename === 'BANKWALLET' ? 'Bank Account' : 'Alt Account' }}
            </p>
            <small>Rights: {{ getShareDirection(notification.notification.invitation.shareDirection) }}</small>
          </div>
        </div>

        <!-- Wallet Share Alias -->
        <validation-observer ref="walletShareAliasForm">
          <validation-provider
            #default="{ errors }"
            name="alias"
            rules="required"
          >
            <b-form-group
              label="Alias for Shared Account"
            >
              <b-form-input
                v-model="description"
                type="text"
                placeholder="Alias"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </validation-observer>
      </b-overlay>
    </b-modal>

  </b-media>
</template>
<script>
import {
  BMedia, BAvatar, BModal, BOverlay, BButton, BFormGroup, BFormInput, BBadge,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import useApollo from '@/plugins/graphql/useApollo'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import EventBus from '@/event-bus'
import useWallet from '@/utils/wallet'

const { getWalletIcon } = useWallet()

export default {
  components: {
    BMedia,
    BAvatar,
    BModal,
    BOverlay,
    BButton,
    BFormGroup,
    BFormInput,
    BBadge,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    notification: {
      type: [Array, Object],
      default: () => {},
    },
    unReadNotifications: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      required,
      getWalletIcon,
      self: getUserData(),
      isProcessing: false,
      description: '',
    }
  },
  computed: {
    displayNotification() {
      const {
        notification, isRead, uuid, createdAt,
      } = this.notification

      const {
        creator, share, walletShareInviteAction, invitation,
      } = notification

      let title = ''
      let subtitle = ''
      let type = 'light-primary'
      const icon = 'ShareIcon'

      if (walletShareInviteAction === 'REQUESTED') {
        title = 'Account Share Invitation!'
        subtitle = `${creator.name} wants to share '${share.description}' with ${invitation.shareDirection === 'BOTH' ? 'inbound and outbound' : invitation.shareDirection.toLowerCase()} rights with you.'`
        // eslint-disable-next-line no-nested-ternary
        if (invitation.processedAt) type = invitation.accepted ? 'light-success' : 'light-danger'
      } else if (walletShareInviteAction === 'APPROVED') {
        title = 'Account Share Accepted!'
        subtitle = `${creator.uuid === this.self.uuid ? 'You have' : `${creator.name} has`} accepted the invitation for sharing of '${share.description}'.`
        type = 'light-success'
      } else {
        title = 'Account Share Declined!'
        subtitle = `${creator.uuid === this.self.uuid ? 'You have' : `${creator.name} has`} declined the invitation for sharing of '${share.description}'.`
        type = 'light-danger'
      }

      return {
        title, subtitle, type, icon, avatar: creator.avatar, isRead, uuid, createdAt,
      }
    },
  },
  methods: {
    getShareDirection(direction) {
      switch (direction) {
        case 'INBOUND':
          return 'In Bound'
        case 'OUTBOUND':
          return 'Out Bound'
        default:
          return 'In Bound and Out Bound'
      }
    },
    handleSubmit(bvModal) {
      bvModal.preventDefault()
      if (bvModal.trigger === 'ok') {
        this.$refs.walletShareAliasForm.validate().then(success => {
          if (success) {
            this.approveWalletShare(true)
          }
        })
      } else this.approveWalletShare(false)
    },
    approveWalletShare(approved) {
      this.isProcessing = true
      const params = {
        shareUid: this.notification.notification.share.uuid,
        input: {
          checksum: this.notification.notification.invitation.checksum,
          description: this.description,
        },
        decline: !approved,
      }

      useApollo.users.approveWalletShareInvite(params)
        .then(response => {
          this.showSuccessMessage({
            data: {
              message: response.data.approveWalletShareInvite.message,
            },
          })
          EventBus.$emit('wallet-share-approved')
        }).finally(() => {
          this.$emit('refetch')
          this.isProcessing = false
          this.$bvModal.hide(`wallet-share-invite-${this.notification.notification.invitation.checksum}-modal`)
        })
    },
    isInviteRevoked(invites, checksum) {
      return invites.find(invite => invite.checksum === checksum).isInvalid
    },
  },
}
</script>

<style scoped>
  .fa-24 {
    font-size: 24px;
  }
</style>
