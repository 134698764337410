<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        v-b-tooltip="'Notifications'"
        :badge="unReadNotifications"
        badge-classes="bg-danger"
        class="text-body text-primary"
        icon="BellIcon"
        size="21"
      />
    </template>

    <notification-body />
  </b-nav-item-dropdown>

</template>

<script>
import { BNavItemDropdown } from 'bootstrap-vue'
import NotificationBody from './NotificationBody.vue'

export default {
  components: {
    BNavItemDropdown,
    NotificationBody,
  },
  computed: {
    unReadNotifications() {
      return this.$store.state.notification.unReadNotifications > 0 ? this.$store.state.notification.unReadNotifications : 0
    },
  },
}
</script>
