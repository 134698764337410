<template>
  <b-media
    class="notification-card "
    :class="(!notification.isRead && unReadNotifications!=0) && 'notification-card--active'"
  >
    <template #aside>
      <b-avatar
        size="42"
        :variant="displayNotification.type"
      >
        <feather-icon
          :icon="displayNotification.icon"
        />
      </b-avatar>
    </template>
    <p class="media-heading mb-0 mb-md-1">
      <span class="font-weight-bolder">
        {{ displayNotification.title }}
      </span>
    </p>
    <small class="notification-text">{{ displayNotification.subtitle }}</small>
    <div class="text-right">
      <small class="text-mute">
        {{ fromNow(UTCtoLocal(displayNotification.createdAt)) }}
      </small>
    </div>
  </b-media>
</template>
<script>
import { BMedia, BAvatar } from 'bootstrap-vue'

export default {
  components: {
    BMedia,
    BAvatar,
  },
  props: {
    notification: {
      type: [Array, Object],
      default: () => {},
    },
    unReadNotifications: {
      type: [Number, String],
      default: 0,
    },
  },
  computed: {
    displayNotification() {
      const currentUser = JSON.parse(localStorage.getItem('userData'))
      const {
        notification, isRead, uuid, createdAt,
      } = this.notification
      const {
        user, project, active,
      } = notification

      // eslint-disable-next-line no-nested-ternary
      const displayUser = user.uuid === currentUser.uuid ? 'You have been' : `${user.name} has been`
      const action = active ? 'activated in' : 'deactivated from'

      const title = active ? 'Activated in Project!' : 'Deactivated From Project!'
      const subtitle = `${displayUser} ${action} the project - ${project.name}`
      const type = active ? 'light-success' : 'light-danger'
      const icon = active ? 'UserCheckIcon' : 'UserXIcon'

      return {
        title, subtitle, type, icon, isRead, uuid, createdAt,
      }
    },
  },
}
</script>
