<template>
  <div class="project-roadblock">
    <b-skeleton-wrapper :loading="initialLoading">
      <template #loading>
        <b-card>
          <b-skeleton width="85%" />
          <b-skeleton width="75%" />
          <b-skeleton width="70%" />
        </b-card>
      </template>

      <div v-if="total">
        <div :class="IS_MOBILE() ? '' : 'd-flex justify-content-between align-items-center'">
          <h3 class="font-sm">
            Please Select A Project
            <br class="d-block d-md-none">
            <span>
              <span v-if="isOwner">
                <span class="d-none d-md-inline">/ </span>
                <b-link v-b-modal.create-project-modal>Create A Project</b-link>
              </span>
              <span v-if="isAdmin">
                <span class="d-none d-md-inline">/ </span>
                <b-link v-b-modal.create-company-modal>Create A Company</b-link>
              </span>
            </span>
          </h3>

          <h6 class="small text-right">Showing
            {{ projects.length }}
            of {{ searchTotal ? searchTotal : total }}
          </h6>
        </div>
        <b-card no-body>
          <b-card-body class="p-0">
            <b-form-input
              v-model="term"
              placeholder="Search project"
              style="padding: 30px;border: none"
              @input="onSearch"
            />
          </b-card-body>
        </b-card>

        <b-overlay
          id="tour-select-project"
          :show="isLoading"
        >
          <swiper
            v-if="term ? searchTotal : total"
            class="swiper"
            :options="swiperOptions"
          >
            <swiper-slide
              v-for="p, index of projects"
              :key="index"
              class="mb-2"
            >
              <b-card
                class="ecommerce-card mx-1"
                role="button"
                @click="updateSelectedProject(p.uuid)"
              >
                <b-card-header>
                  <div>
                    <b-card-text class="h4">
                      {{ p.name }}
                    </b-card-text>

                    <p>
                      {{ p.company.name }}
                    </p>
                  </div>
                </b-card-header>

                <b-card-footer>
                  {{ p.users.total }} Members
                </b-card-footer>
              </b-card>
            </swiper-slide>
            <div
              v-show="term ? searchTotal > slidesInView : total > slidesInView"
              slot="button-prev"
              class="swiper-button-prev"
              @click="prev"
            />
            <div
              v-show="term ? searchTotal > slidesInView : total > slidesInView"
              slot="button-next"
              class="swiper-button-next"
              @click="next"
            />
          </swiper>
          <b-card
            v-else
            class="text-center"
          >
            <h4>No Projects Found!</h4>
          </b-card>
        </b-overlay>
      </div>

      <b-card
        v-else-if="isOwner"
        id="tour-create-project"
      >
        <h3 class="mb-2">
          Get Started!
        </h3>
        <b-alert
          show
          variant="warning"
          class="small text-warning cursor-pointer p-1"
        >
          <div
            v-b-modal.create-project-modal
            class="d-flex align-items-center"
            @click="pauseTour"
          >
            <feather-icon
              icon="AlertTriangleIcon"
              class="mr-1"
            />
            <p>
              Click here to create your first project!
            </p>
          </div>
        </b-alert>
      </b-card>

      <b-alert
        v-else
        variant="danger"
        show
        class="p-1 text-center"
      >
        <feather-icon
          icon="FrownIcon"
          size="72"
          class="mb-1"
        />
        <h1 class="text-danger">
          Oops!
        </h1>
        <p>Looks like you have been deactivated from your projects.</p>
        <p>You should be active in at least one project to go further.</p>
      </b-alert>

      <create-edit-project
        v-if="company || isOwner"
        :company-id="company ? company.uuid : null"
        :owner="self.uuid"
        @onSave="fetchProjects('initial', false)"
      />

      <b-modal
        id="create-company-modal"
        title="Create a new company"
        hide-footer
        no-close-on-backdrop
      >
        <company-form @onSave="$bvModal.hide('create-company-modal')" />
      </b-modal>
    </b-skeleton-wrapper>

  </div>
</template>
<script>
import {
  BCard, BCardBody, BFormInput, BCardHeader, BCardFooter, BCardText, BOverlay, BAlert, BSkeletonWrapper, BSkeleton, BLink, BModal,
} from 'bootstrap-vue'
import useApollo from '@/plugins/graphql/useApollo'
import { getUserData } from '@/auth/utils'
import { checkPermission, projectSettings } from '@/utils/permissions'
import EventBus from '@/event-bus'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import CreateEditProject from '@/views/Settings/company/projects/createEdit.vue'
import _ from 'lodash'
import CompanyForm from '@/views/Settings/company/main/CompanyForm.vue'
import { continueTour, pauseTour, setComponentReady } from '@/views/tour/tour'

import 'swiper/css/swiper.css'
import { ownerTourIndexes } from '@/views/tour/tourConstants'
import { mapPermissionSlug } from '@/const/common'

export default {
  components: {
    BCard,
    BCardBody,
    BFormInput,
    BCardHeader,
    BCardFooter,
    BCardText,
    BOverlay,
    BSkeletonWrapper,
    BSkeleton,
    BAlert,
    BLink,
    BModal,
    Swiper,
    SwiperSlide,
    CreateEditProject,
    CompanyForm,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      pauseTour,
      self: getUserData(),
      term: '',
      projects: [],
      allProjects: [],
      sliderPage: 1,
      currentPage: 1,
      total: 0,
      maxPage: 0,
      triggerFetchIndex: 2,
      searchTotal: 0,
      perPage: 8,
      isLoading: false,
      initialLoading: false,
      swiperOptions: {
        slidesPerView: this.IS_MOBILE() ? 1 : 3,
        slidesPerColumn: 2,
        allowTouchMove: false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      company: null,
    }
  },
  computed: {
    slidesInView() {
      return this.swiperOptions.slidesPerColumn * this.swiperOptions.slidesPerView
    },
    isOwner() {
      return this.self.companyRoles?.data.filter(companyRole => companyRole.role.name === 'owner').length
    },
    isAdmin() {
      return this.self.isAdmin
    },
    companyRole() {
      return this.self.companyRole?.data?.[0]?.role.name
    },
  },
  created() {
    if (this.self) this.fetchProjects('initial')

    this.$store.dispatch('project/setSelectedUser', null)
  },
  methods: {
    next() {
      this.sliderPage += 1
      if (this.sliderPage === this.triggerFetchIndex
        && (this.searchTotal ? this.projects.length < this.searchTotal : this.projects.length < this.total)) {
        this.currentPage += 1
        this.triggerFetchIndex += 4
        this.$nextTick(() => {
          if (this.term !== '') this.search()
          else this.fetchProjects()
        })
      }
    },
    prev() {
      this.sliderPage -= 1
    },
    fetchProjects(type = '', stack = true) {
      const { projects } = this
      if (type === 'initial') this.initialLoading = true
      else this.isLoading = true

      useApollo.project.getProjects({ first: this.perPage, page: this.currentPage, includeInactive: false }).then(response => {
        if (type === 'initial') this.allProjects = response.data?.me?.projects?.data
        this.total = response.data?.me?.projects?.total
        this.$store.dispatch('tour/setProjectCount', this.total)
        this.maxpage = Math.ceil(this.total / this.perPage)
        if (stack) {
          projects.push(...response.data?.me?.projects?.data)
          this.projects = projects
        } this.projects = this.allProjects
        if (!this.total) this.getMyCompanies()
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.initialLoading = false
        this.isLoading = false
        this.$nextTick(() => {
          setComponentReady()
          if (this.$store.state.tour.isTouringPaused) continueTour({ step: ownerTourIndexes.TOUR_SELECT_A_PROJECT })
        })
      })
    },
    onSearch: _.debounce(function () {
      this.projects = []
      this.resetSliderConfig()
      this.$nextTick(() => this.search())
    }, 500),
    search() {
      if (!this.term.trim()) {
        this.searchTotal = 0
        this.$nextTick(() => {
          this.projects = [...this.allProjects]
        })
        return
      }
      this.isLoading = true
      useApollo.project.searchProjects({
        keyword: this.term, first: this.perPage, page: this.currentPage,
      }).then(response => {
        const { projects } = this
        projects.push(...response.data?.me?.projects?.data)
        this.projects = projects
        this.searchTotal = response.data?.me?.projects?.total ?? 0
      }).finally(() => { this.isLoading = false })
    },
    updateSelectedProject(value, project = null) {
      pauseTour()
      this.isLoading = true
      // eslint-disable-next-line no-param-reassign
      if (!project) project = this.projects.find(p => p.uuid === value)
      this.$store.dispatch('project/SET_PROJECT_BASIC_REQUIREMENT', {
        project,
        value,
      }).then(() => {
        checkPermission(['timezones.update', 'permissions.manage', 'cms.update', 'productivity.update', 'company.edit', 'leads.create', 'leads.follow', 'leads.close', 'project.manageusers', 'campaigns.create', 'campaigns.edit'], permissions => {
          // const userPermissions = [permissions]
          const userPermissions = Object.entries(permissions).map(([key, val]) => ({ key: mapPermissionSlug[key], value: val }))
          this.$store.dispatch('permissions/setPermissions', userPermissions)
        })
        projectSettings().then(response => {
          this.$store.dispatch('project/setProjectSettings', {
            enableCloudService: response?.enableCloudService,
          })
        })
      }).finally(() => {
        EventBus.$emit('project-selected')
        this.$router.replace({ name: 'home' })
      })
    },
    resetSliderConfig() {
      this.sliderPage = 1
      this.currentPage = 1
      this.triggerFetchIndex = 2
    },
    getMyCompanies() {
      useApollo.users.getMyCompanies().then(response => {
        this.company = response.data.me.companyRoles?.data[0]?.company
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import "@core/scss/base/pages/project-roadblock.scss";
</style>

<style lang="scss" scoped>
.content-wrapper{
  min-height:80vh;
  .content-body{
    height:100%;
  }
}
</style>
