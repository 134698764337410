<template>
  <b-media
    class="notification-card "
    :class="(!notification.isRead && unReadNotifications!=0) && 'notification-card--active'"
  >
    <template #aside>
      <b-avatar
        size="42"
        :variant="displayNotification.type"
        :src="getAvatar(displayNotification.avatar)"
      />
    </template>
    <div class="media-heading d-flex justify-content-between mb-0 mb-md-1">
      <p class="font-weight-bolder text-capitalize mb-0">
        {{ displayNotification.title }}
      </p>

    </div>
    <small class="notification-text">{{ displayNotification.subtitle }}</small>
    <div class="text-right">
      <small class="text-mute">
        {{ fromNow(UTCtoLocal(displayNotification.createdAt)) }}
      </small>
    </div>

  </b-media>
</template>
<script>
import {
  BMedia, BAvatar,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import useApollo from '@/plugins/graphql/useApollo'
import { required } from 'vee-validate/dist/rules'
import EventBus from '@/event-bus'

export default {
  components: {
    BMedia,
    BAvatar,
  },
  props: {
    notification: {
      type: [Array, Object],
      default: () => {},
    },
    unReadNotifications: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      required,
      self: getUserData(),
      isProcessing: false,
      description: '',
    }
  },
  computed: {
    displayNotification() {
      const {
        notification, isRead, uuid, createdAt,
      } = this.notification

      const {
        creator, share,
      } = notification

      let title = ''
      let subtitle = ''
      let type = 'light-primary'
      const icon = 'ShareIcon'

      title = 'Account Share Revoked'
      subtitle = `${creator.uuid === this.self.uuid ? 'You have' : `${creator.name} has`} revoked sharing of account '${share.description}' with you.`
      type = 'light-warning'

      return {
        title, subtitle, type, icon, avatar: creator.avatar, isRead, uuid, createdAt,
      }
    },
  },
  methods: {
    getShareDirection(direction) {
      switch (direction) {
        case 'INBOUND':
          return 'In Bound'
        case 'OUTBOUND':
          return 'Out Bound'
        default:
          return 'In Bound and Out Bound'
      }
    },
    handleSubmit(bvModal) {
      bvModal.preventDefault()
      if (bvModal.trigger === 'ok') {
        this.$refs.walletShareAliasForm.validate().then(success => {
          if (success) {
            this.approveWalletShare(true)
          }
        })
      } else this.approveWalletShare(false)
    },
    approveWalletShare(approved) {
      this.isProcessing = true
      const params = {
        shareUid: this.notification.notification.share.uuid,
        input: {
          checksum: this.notification.notification.invitation.checksum,
          description: this.description,
        },
        decline: !approved,
      }

      useApollo.users.approveWalletShareInvite(params)
        .then(response => {
          this.showSuccessMessage({
            data: {
              message: response.data.approveWalletShareInvite.message,
            },
          })
          EventBus.$emit('wallet-share-approved')
        }).finally(() => {
          this.$emit('refetch')
          this.isProcessing = false
          this.$bvModal.hide(`wallet-share-invite-${this.notification.notification.invitation.checksum}-modal`)
        })
    },
  },
}
</script>

<style scoped>
  .fa-24 {
    font-size: 24px;
  }
</style>
