<template>
  <b-media
    class="notification-card "
    :class="(!notification.isRead && unReadNotifications!=0) && 'notification-card--active'"
  >
    <template #aside>
      <b-avatar
        size="42"
        :variant="displayNotification.type"
      >
        <feather-icon
          :icon="displayNotification.icon"
        />
      </b-avatar>
    </template>
    <p class="media-heading mb-0 mb-md-1">
      <span class="font-weight-bolder">
        {{ displayNotification.title }}
      </span>
    </p>
    <small
      class="notification-text"
      v-html="displayNotification.subtitle"
    />
    <div class="text-right">
      <small class="text-mute">
        {{ fromNow(UTCtoLocal(displayNotification.createdAt)) }}
      </small>
    </div>
  </b-media>
</template>
<script>
import { BMedia, BAvatar } from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BMedia,
    BAvatar,
  },
  props: {
    notification: {
      type: [Array, Object],
      default: () => {},
    },
    unReadNotifications: {
      type: [Number, String],
      default: 0,
    },
  },
  computed: {
    displayNotification() {
      const {
        creator, notification, isRead, uuid, createdAt,
      } = this.notification

      let title = ''
      let subtitle = ''
      // eslint-disable-next-line no-underscore-dangle
      const isFamilyDestination = notification.destination.__typename === 'WorkzoneWallet' ? 1 : 0
      const isCreatorMe = creator.uuid === getUserData().uuid

      if (notification.walletTransferAction === 'WITHDRAW') {
        title = 'Fund Withdrawn!'
        subtitle = `${this.formatAmount(notification.amount)} has been withdrawn from <strong>${notification.source.description}</strong> ${isCreatorMe ? '' : `by ${creator.name}`}`
      } else if (notification.walletTransferAction === 'REFUND') {
        title = 'Refund!'
        subtitle = `${this.formatAmount(notification.amount)} has been refunded to <strong>${notification.source.description}</strong>`
      } else if (notification.walletTransferAction === 'DEPOSIT') {
        title = 'Fund Deposited!'
        subtitle = `${this.formatAmount(notification.amount)} has been deposited into ${isFamilyDestination ? 'the entire family - ' : ''} <strong>${notification.destination.description}</strong> ${isCreatorMe ? '' : `by ${creator.name}`}`
      } else {
        title = 'Fund Transferred!'
        subtitle = `${this.formatAmount(notification.amount)} has been transferred from <strong>${notification.source.description}</strong> into ${isFamilyDestination ? 'the entire family - ' : ''} <strong>${notification.destination.description}</strong>`
      }

      return {
        title, subtitle, type: 'light-success', icon: 'DollarSignIcon', isRead, uuid, createdAt,
      }
    },
  },
}
</script>
